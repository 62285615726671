import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Banner from "../components/Banner";
import {Link} from "gatsby";
import {useTranslation} from "gatsby-plugin-react-i18next";

const StaticSites = () => {

    const {t} = useTranslation();

    return (
        <Layout>
            <SEO/>
            <Banner title={'Strony internetowe'} classNames={'is-green'}/>
            <div id="main">
                <section className="inner">
                    <header className="major">
                        <h2>{t('static-sites.title')}</h2>
                    </header>
                    <p>
                        {t('static-sites.intro')}
                    </p>
                    <ul className="static-sites__columns">
                        <li>
                            <i className="fas fa-space-shuttle"/>
                            <h2>
                                {t('static-sites.speed')}
                            </h2>
                        </li>
                        <li>
                            <i className="fas fa-code"/>
                            <h2>
                                {t('static-sites.flexibility')}
                            </h2>
                        </li>
                        <li>
                            <i className="fas fa-shield-alt"/>
                            <h2>
                                {t('static-sites.security')}
                            </h2>
                        </li>
                    </ul>
                    <ul className="actions" style={{marginTop: '20px'}}>
                        <li>
                            <Link to="/#contact" className="button next scrolly">
                                {t('buttons.contact')}
                            </Link>
                        </li>
                    </ul>
                </section>
            </div>
        </Layout>
    );
}

export default StaticSites;
